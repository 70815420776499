.loader {
	display: flex;
	padding: 1rem 0rem;
}

.bar {
	width: 4px;
	height: 15px;
	background-color: white;
	margin: 0 3px;
	animation: grow-shrink 1.2s infinite ease-in-out;
	border-radius: 2px;
}
.bar-1 {
	animation-delay: 0s;
}

.bar-2 {
	animation-delay: 0.2s;
}

.bar-3 {
	animation-delay: 0.4s;
}

.new-page-loader-wrapper {
	display: grid;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	position: absolute;
	align-items: center;
	justify-items: center;
	z-index: 999999;
	background: black;
}

/* Scrubber Icon Animation */
#scrubber-loader-icon {
	fill: white;
	max-width: 50px;

	width: 50px;
	/* 2s duration, infinite iterations */
	animation: scrubberMove 8s infinite;
}

.dirty-path {
	display: flex;
	justify-self: flex-end;
	width: 100px;
	height: 4px;
	background-color: rgb(69, 30, 0);
	transform-origin: left;
	animation: dirtypath 8s infinite;
}
.clean-path {
	display: flex;
	justify-self: flex-end;
	width: 100px;
	height: 4px;
	background-color: rgb(255, 255, 255);
	transform-origin: left;
	animation: cleanpath 8s infinite;
}

@keyframes scrubberMove {
	0% {
		transform: translateX(0) scaleX(1);
	}
	49.9% {
		transform: translateX(100px) scaleX(1);
	}
	/* Instant flip at 50% */
	50% {
		transform: translateX(100px) scaleX(-1);
	}
	100% {
		transform: translateX(0) scaleX(-1);
		opacity: 1;
	}
}

@keyframes dirtypath {
	0% {
		width: 100px;
	}
	50% {
		width: 0px;
	}
	100% {
		justify-self: flex-start;
		width: 0px;
	}
}

@keyframes cleanpath {
	0% {
		width: 0px;
		justify-self: flex-start;
	}
	50% {
		width: 100px;
	}

	100% {
		justify-self: flex-start;
		width: 0px;
	}
}

@keyframes grow-shrink {
	0%,
	100% {
		height: 10px;
		opacity: 0.3;
	}
	50% {
		height: 20px;
		opacity: 1;
	}
}

@keyframes grow-shrink {
	0%,
	100% {
		height: 10px;
		opacity: 0.3;
	}
	50% {
		height: 20px;
		opacity: 1;
	}
}

@keyframes page-grow-shrink {
	0%,
	100% {
		height: 4rem;
		opacity: 0.3;
	}
	50% {
		height: 2rem;
		opacity: 1;
	}
}
